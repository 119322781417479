const _  = require('lodash')

exports.extractTreePath = (fullPath) => 
{
    if (fullPath.length === 0) {
        return [];
    }
    const paths = [];

    const parts = fullPath.split('/').map((x) => _.trim(x));
    for(let i = 0; i < parts.length; i++)
    {
        const parentId = exports.makePath(_.take(parts, i));
        const childId = exports.makePath(_.take(parts, i+1));

        paths.push({
            parentId: parentId,
            childId: childId,
        })
    }

    return paths;
}

exports.makePath = (parts) => 
{
    return parts.join(' / ');
}