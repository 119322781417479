const CAPTCHA_KEY = '6LdOVrIZAAAAAEeF-vWn8QLrfFi8QJ0NFH9UB9xJ'

const DEMO_REQUEST = {
    mainTitle: 'Demo Request',
    subTitle: 'We can learn about your needs and present a 30-minute Kubevious SaaS Demo relevant to your own use case. Submit your request to schedule a meeting with our team below.',
    hubspotPortalId: '21176504',
    hubspotFormId: '0cfd1b5a-ea8b-4b89-98a2-2f2b29248786',
    hubspotMeetingUrl: 'https://meetings.hubspot.com/ruben-hakopian?embed=true',
}

const SAMURAI_REQUEST = {
    mainTitle: 'Samurai Plan Request',
    subTitle: 'Are you interested in Samurai plan? Submit your request to schedule a meeting with our team below.',
    hubspotPortalId: '21176504',
    hubspotFormId: '445888f5-aad2-4969-b0fe-df0ed604aab1',
    hubspotMeetingUrl: 'https://meetings.hubspot.com/ruben-hakopian?embed=true',
}

const SHOGUN_REQUEST = {
    mainTitle: 'Shogun Plan Request',
    subTitle: 'Are you interested in Shogun plan? Submit your request to schedule a meeting with our team below.',
    hubspotPortalId: '21176504',
    hubspotFormId: '5ee53aeb-33fe-41d9-8e22-0f791508d05d',
    hubspotMeetingUrl: 'https://meetings.hubspot.com/ruben-hakopian?embed=true',
}

const OSS_REQUEST = {
    mainTitle: 'Open-Source Support Request',
    subTitle: 'Do you running Kubevious Open-Source and need extra help and support? Submit your request to schedule a meeting with our team below.',
    hubspotPortalId: '21176504',
    hubspotFormId: '6327506d-3ebe-4347-a6b4-836dd47f1e0f',
    hubspotMeetingUrl: 'https://meetings.hubspot.com/ruben-hakopian?embed=true',
}

const CONSULTATION_REQUEST = {
    mainTitle: 'Consultation Request',
    subTitle: 'You\'re not sure which plan works best? We would like to learn about your use case and recommend a suitable solution. Submit your request to schedule a meeting with our team below.',
    hubspotPortalId: '21176504',
    hubspotFormId: '2a3dddc7-2f19-4bf2-9d1a-a2969a2fadc7',
    hubspotMeetingUrl: 'https://meetings.hubspot.com/ruben-hakopian?embed=true',
}


const CONTACT_PAGE = {
    mainTitle: 'How can we help?',
    hubspotPortalId: '21176504',
    hubspotFormId: '53b55302-5f93-4060-99d2-4e8d15fc701f',
}

const VALIDATOR_SUBMIT_FORM = {
    hubspotPortalId: '21176504',
    hubspotFormId: 'c8981316-52af-4d74-a4eb-38052b97c86c',
}


module.exports.DEMO_REQUEST = DEMO_REQUEST;
module.exports.SAMURAI_REQUEST = SAMURAI_REQUEST;
module.exports.SHOGUN_REQUEST = SHOGUN_REQUEST;
module.exports.OSS_REQUEST = OSS_REQUEST;
module.exports.CONSULTATION_REQUEST = CONSULTATION_REQUEST;

module.exports.CONTACT_PAGE = CONTACT_PAGE;

module.exports.VALIDATOR_SUBMIT_FORM = VALIDATOR_SUBMIT_FORM;

// module.exports.CAPTCHA_KEY = CAPTCHA_KEY;
