import React from 'react'
import _ from 'lodash'
import { graphql } from 'gatsby'
import Layout from '../../components/Layout'
import { defineCustomElements as deckDeckGoHighlightElement } from '@deckdeckgo/highlight-code/dist/loader'

import * as styles from './styles.module.css'

import { DocsMenu } from '../../components/DocsMenu'
import { DocsContent } from '../../components/DocsContent'

export default function DocsPage({ data, location, pageContext }) {

    deckDeckGoHighlightElement();

    const { docsPage } = data

    const title = docsPage?.title ?? '';

    const postUrl = docsPage.slug;

    const date = new Date();

    const description = `Kubevious Documentation. ${title}`;
    const jsonLDScript = 
        {
            "@context": "https://schema.org",
            "@type": "NewsArticle",
            "mainEntityOfPage": {
                 "@type": "WebPage",
                 "@id": "https://kubevious.io/"
            },
            "headline": title,
            "url": `https://kubevious.io${postUrl}`,
            "name": title,
            "description": description,
            "datePublished": date.toISOString(),
            "dateModified": date.toISOString(),
            "publisher": {
                "@type": "Organization",
                "name": "Kubevious",
                "logo": {
                    "@type": "ImageObject",
                    "url": "https://kubevious.io/img/logo.png"
                }
            }
        };
    

    return (
        <Layout sectionName="docs"
                seoUrl={postUrl}
                seoDescription={description}
                seoTitle={title}
                seoKeywords={`Kubevious Documentation. ${title}.`}
                seoType="article"
                seoImage="/screens/site-docs.png"
                jsonLDScript={jsonLDScript}
                skipBottomPadding
                >

            <div className={styles.container}>
                <div className={styles.menuContainer}>
                    <DocsMenu location={location} />
                </div>

                <div className={styles.contentWrapper}>

                    <div className={styles.contentInner}>
                        <DocsContent data={docsPage} postUrl={postUrl} />
                    </div>
                </div>
            </div>
            
            
        </Layout>
    )
}

export const pageQuery = graphql`
    query($id: String!) {
        docsPage(id: {eq: $id}) {
            id
            title
            slug
            fullPath
            category
            validatorId
            contentData,
            embedValidatorSubmitForm
            parent {
                ... on MarkdownRemark {
                    html
                }
            }
        }
    }
`
