import React, { useState, useEffect, Fragment } from 'react'
import _ from 'the-lodash';
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight, faCaretDown } from '@fortawesome/free-solid-svg-icons'

import { DocsTreeBuilder } from '../../../plugins/kubevious-docs/tree-builder';

import cx from 'classnames';
import * as styles from './styles.module.css'

export const DocsMenu = ({ location }) => {
    const pathname = location.pathname ? location.pathname : '';

    const window = global.window;

    const [, setExpandData] = useState(null);

    const data = useStaticQuery(graphql`
        {
            allDocsPage {
                edges {
                    node {
                        id
                        slug
                        fullPath
                        category
                        title
                        order
                    }  
                }
            }
        }
    `);

    const [treeBuilder, setTreeBuilder] = useState(null);
    useEffect(() => {
        const pageNodes = data.allDocsPage.edges.map((x) => x.node);
        
        const x = new DocsTreeBuilder(pageNodes);
        
        setExpandData(x.setupExpansion(window?.myDocsMenuData, pathname));

        setTreeBuilder(x);

    }, [ data ]);

    /*****/

    const handleExpandClick = (id) => {
        const newData = treeBuilder.setIsExpanded(id, !treeBuilder.isExpanded(id));

        setExpandData(newData);
        window.myDocsMenuData = newData;
    }

    const handleNodeClick = (id) => {
        if (treeBuilder.isExpanded(id)) {
            return;
        }
        handleExpandClick(id);
    }

    const renderTreeNode = (node) => {

        const id = node.fullPath;
        const slug = node.slug;

        const childrenCount = treeBuilder.getChildrenCount(id);
        const isExpanded = treeBuilder.isExpanded(id);
        const isActive = slug && (slug === pathname);

        return (
           <div key={id}
                className={styles.treeNodeWrapper}>
                
                <div className={cx(styles.treeNodeContainer,
                                    {
                                        [styles.treeNodeContainerActive]: isActive
                                    })}>

                    <div className={styles.treeNodeLabelWrapper}
                         onClick={() => handleNodeClick(id)} >
                        {slug && <>
                            <Link to={slug}>
                                <div className={styles.treeNodeLabel}>
                                    {node.name}
                                </div>
                            </Link>
                        </>}
                        {!slug && <>
                            <div className={styles.treeNodeLabel}>
                                {node.name}
                            </div>
                        </>}
                        
                    </div>

                    {(childrenCount > 0) && 
                        <div className={styles.treeIconContainer}
                             onClick={() => handleExpandClick(id)} >
                            <FontAwesomeIcon
                                icon={isExpanded ? faCaretDown : faCaretRight}
                                color="#777777"
                            />          
                        </div>
                    }

                </div>

                {(isExpanded && (childrenCount > 0)) && 
                    <div className={styles.childrenContainer}>
                        {renderTreeContents(id)}
                    </div>
                }

            </div>
        );
    }


    const renderTreeContents = (id) => {

        const children = treeBuilder.getChildren(id);

        return <>
            <div className={styles.categoryContents}>

                {children && 
                    <div className={styles.nodesWrapper}>
                        {children.map((child, index) => 
                            <Fragment key={index}>
                                {renderTreeNode(child)}
                            </Fragment>
                        )}
                    </div>
                }

            </div>
        </>;
    }

    return (
        <div className={styles.content}>

            {treeBuilder && renderTreeContents("")}

        </div>
    )
}
