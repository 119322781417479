const _  = require('lodash')

const { extractTreePath } = require('./utils');

export class DocsTreeBuilder
{
    constructor(pageNodes)
    {
        // Tree Data
        this._slugMap = {};
        this._treeNodeMap = {};
        for(const page of pageNodes)
        {
            const pageNode = this.getNode(page.fullPath);
            pageNode.slug = page.slug;
            if (page.order) {
                pageNode.order = page.order;
            }

            if (page.slug) {
                this._slugMap[page.slug] = page.fullPath;
            }
    
            const childPaths = extractTreePath(page.fullPath);
            for(const childPath of childPaths)
            {
                const parentNode = this.getNode(childPath.parentId);
                const childNode = this.getNode(childPath.childId);
                parentNode.childrenIdMap[childPath.childId] = true;
                childNode.parentId = childPath.parentId;
            }
        }

        for(const pageNode of _.values(this._treeNodeMap))
        {
            pageNode.children = _.chain(pageNode.childrenIdMap)
                                 .keys()
                                 .map(x => this.getNode(x))
                                 .orderBy([x => x.order, x => x.name])
                                 .value()
        }

        // Sequence
        this._docsSequence = [];
        this._visitNode("");
        for(let i = 0; i < this._docsSequence.length - 1; i++)
        {
            const prevId = this._docsSequence[i];
            const nextId = this._docsSequence[i+1];

            this.getNode(prevId).nextId = nextId;
            this.getNode(nextId).prevId = prevId;
        }

        // console.error("DocsTreeBuilder. SLUG MAP: ", this._slugMap);
        // console.error("DocsTreeBuilder. this._docsSequence: ", this._docsSequence);
        // console.error("DocsTreeBuilder. _treeNodeMap MAP: ", this._treeNodeMap);
    }

    findNode(id)
    {
        return this._treeNodeMap[id] ?? null;
    }

    getNode(id)
    {
        if (!this._treeNodeMap[id])
        {
            const parts = id.split('/').map((x) => _.trim(x));
            this._treeNodeMap[id] = {
                fullPath: id,
                name: (id === '') ? 'Kubevious Documentation' : _.last(parts),
                slug: null,
                order: 9999,
                parentId: null,
                childrenIdMap: {},
                children: [],

                nextId: null,
                prevId: null,
            };
        }

        return this._treeNodeMap[id];   
    }

    getChildren(id)
    {
        const treeNode = this._treeNodeMap[id];
        if (!treeNode) {
            return [];
        }

        return treeNode.children;
        // const childrenIds = _.keys(treeNode.childrenIdMap);
        // return childrenIds;
    }

    getChildrenCount(id)
    {
        return this.getChildren(id).length;
    }

    // Tree Expansion Logic Begin

    setupExpansion(myDocsMenuData, url)
    {
        this._expandData = myDocsMenuData ?? this._buildInitialExpansion();

        let pageId = this._slugMap[url];
        if (!pageId) {
            return;
        }

        while (pageId) {
            this._expandData[pageId] = true;

            const pageInfo = this._treeNodeMap[pageId];
            pageId = pageInfo.parentId;
        }

        return _.clone(this._expandData)
    }

    _buildInitialExpansion()
    {
        const expandData = { "": true };
        for(const child of this.getChildren(""))
        {
            expandData[child.fullPath] = true;
        }
        return expandData;
    }

    isExpanded(id)
    {
        return this._expandData[id] ?? false;
    }

    setIsExpanded(id, value)
    {
        const data = _.clone(this._expandData);
        if (value) {
            data[id] = true;
        } else {
            delete data[id];
        }
        this._expandData = data;
        return data;
    }

    // Tree Expansion Logic End

    _visitNode(id)
    {
        this._docsSequence.push(id);

        const children = this.getChildren(id);
        for(const child of children)
        {
            this._visitNode(child.fullPath);
        }
    }
}