import _ from 'the-lodash';
import React, { useState, useEffect, Fragment } from 'react'
import { useStaticQuery, graphql, Link } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'

import SocialNetworks from '../SocialNetworks'

import * as styles from './styles.module.css'

import { DocsTreeBuilder } from '../../../plugins/kubevious-docs/tree-builder';

import { VALIDATORS_METADATA } from '@kubevious/entity-meta';

import HubspotForm from 'react-hubspot-form'

import { VALIDATOR_SUBMIT_FORM } from '../../../content/data/contactPages'

import cx from 'classnames';

export const DocsContent = ({ data, postUrl }) => {

    const [childrenLinks, setChildrenLinks] = useState([]);
    const [docsNavigationPath, setDocsNavigationPath] = useState([]);
    const [treeNode, setTreeNode] = useState(null);
    const [prevTreeNode, setPrevTreeNode] = useState(null);
    const [nextTreeNode, setNextTreeNode] = useState(null);

    const title = (data.fullPath === '') ? 'Kubevious Documentation' : data?.title;
    const remarkHtml = data?.parent?.html;

    const allPagesData = useStaticQuery(graphql`
        {
            allDocsPage {
                edges {
                    node {
                        id
                        slug
                        fullPath
                        category
                        title
                        order
                    }  
                }
            }
        }
    `);

    useEffect(() => {
        const pageNodes = allPagesData.allDocsPage.edges.map((x) => x.node);

        const treeBuilder = new DocsTreeBuilder(pageNodes);

        const children = treeBuilder.getChildren(data.fullPath);
        setChildrenLinks(children);

        const myDocsNavigationPath = [];
        let node = treeBuilder.getNode(data.fullPath);
        setTreeNode(node);
        setPrevTreeNode(treeBuilder.findNode(node.prevId));
        setNextTreeNode(treeBuilder.findNode(node.nextId));

        while(node.parentId !== null)
        {
            let parent = treeBuilder.getNode(node.parentId);
            myDocsNavigationPath.push(parent);
            node = parent;
        }
        setDocsNavigationPath(_.reverse(myDocsNavigationPath));

    }, [ data ]);


    /*****/

    const renderCodeBlock = (code, language) => {

        let prismaLanguage = 'yaml';
        if (language === 'shell') {
            prismaLanguage = 'bash';
        }

        const html = `<deckgo-highlight-code language="${prismaLanguage}" terminal="carbon" theme="dracula">
            <code slot="code">${code}</code>
        </deckgo-highlight-code>`;

        return (<>
            <div dangerouslySetInnerHTML={{ __html: html }} />
        </>)
    }

    const renderPathNavigator = () => {
        if (!docsNavigationPath || docsNavigationPath.length === 0) {
            return <>
            </>;
        } 
     
        return <div className={styles.navigationLinksContainer}>

            {docsNavigationPath.map((x, index) => 
                <Fragment key={index}>
                    <div className={styles.navigationLinkWrapper}>
                        <Link to={x.slug}>
                            <div className={styles.navigationLinkLabel}>
                                {(!(x.fullPath && x.fullPath.length > 0)) && 
                                    <>
                                        <div className={styles.docsNavIcon} />
                                        Docs
                                    </>
                                }
                                {(x.fullPath && x.fullPath.length > 0) && 
                                    <>{x.name}</>
                                }                                    
                                
                            </div>
                        </Link>
                    </div>

                    {(index !== (docsNavigationPath.length - 1)) &&
                        <div className={styles.navigationLinkSeparator}>
                            <FontAwesomeIcon
                                icon={faAngleRight}
                                color="#777777"
                                size="sm"
                            />  
                        </div>
                    }
                </Fragment>)}
        </div>
    }

    const renderChildrenLinks = () => {
        if (!childrenLinks || childrenLinks.length === 0) {
            return <>
            </>;
        } 

        return <div className={styles.childrenLinksContainer}>
            {childrenLinks.map((x, index) => 
                <div key={index}
                     className={styles.childrenLinkWrapper}>
                    <Link to={x.slug}>
                        <div className={styles.childrenLinkLabel}>
                            {x.name}
                        </div>
                    </Link>
                </div>)}
        </div>
    }

    const renderValidator = () => {
        if (!data.validatorId) {
            return <>
            </>;
        }

        const docs = VALIDATORS_METADATA.getValidatorDocs(data.validatorId);

        return <>

            {docs.description && 
                <p>
                    {docs.description}
                </p>
            }   

            {docs.affected && docs.affected.length > 0 && 
                <p>
                    <strong>
                    Affected Resources:
                    </strong> {docs.affected.join(", ")}
                </p>
            }   

            {docs.examples && docs.examples.length > 0 &&
                <div>
                    <h2>
                        {(docs.examples.length > 1) ? "Examples" : "Example"}
                    </h2>

                    <div>
                        {docs.examples.map((x, index) => 
                            <div key={index}>
                                {renderCodeBlock(x?.script ?? '', x?.language ?? 'yaml')}
                            </div>
                        )}
                    </div>
                </div>}

            {docs.resolutions && docs.resolutions.length > 0 &&
                <div>
                    <h2>
                        Resolution
                    </h2>

                    {docs.resolutions.map((x, index) => 
                        <li key={index}>
                            {x}
                        </li>
                    )}
                </div>}

            {docs.resolutionExamples && docs.resolutionExamples.length > 0 &&
                <div>
                    <h2>
                        {(docs.resolutionExamples.length > 1) ? "Resolution Examples" : "Resolution Example"}
                    </h2>

                    <div>
                        {docs.resolutionExamples.map((x, index) => 
                            <div key={index}>
                                {renderCodeBlock(x.script, x.language)}
                            </div>
                        )}
                    </div>
                </div>}


            {docs.externalLinks && docs.externalLinks.length > 0 && <div>
                <h2>
                    Learn More
                </h2>

                {docs.externalLinks.map((link, index) => 
                    <li key={index}>
                        <a href={link}
                           target="_blank"
                           rel="noreferrer noopener"
                           className={styles.externalLink}
                           >
                            {link}
                        </a>
                    </li>
                )}
            </div>}

            {/* <pre>
                {JSON.stringify(docs, null, 4)}
            </pre> */}
        </>;
    }

    const renderValidatorSubmitForm = () => {
        if (!data.embedValidatorSubmitForm) {
            return <>
            </>;
        }

        return <div className={styles.formWrapper}>

            <h2>
                Need other validation rules?
            </h2>

            <p>
                Please let us know if there are other built-in validation rules
                you would like to see in Kubevious to detect misconfigurations
                and violations to best practices. Optionally, you can provide
                your details so we can reach out to you with follow-up questions.
            </p>

            <HubspotForm
                portalId={VALIDATOR_SUBMIT_FORM.hubspotPortalId}
                formId={VALIDATOR_SUBMIT_FORM.hubspotFormId}
            />
        </div>;
    }

    const renderSocialShare = () => {
        return <> 
            <div
                className={styles.socialNetworks}
            >
                <div className={styles.socialNetworksShareMsg}>
                    Share this article on:
                </div>
                <SocialNetworks
                    title={title}
                    text={''}
                    tags={['kubernetes', 'validator', 'bestpractices']}
                    url={postUrl}
                />
            </div>
        </>
    }

    const renderNextPrevLinks = () => {
       
        if (!treeNode) {
            return <>
            </>;
        }
        {JSON.stringify(treeNode, null, 4)}

        return <div className={styles.nextPrevLinks}>

            {prevTreeNode && 
                <Link to={prevTreeNode.slug}>
                    <div className={cx(styles.nextPrevLink, styles.nextPrevLinkLeft)}>
                        <div>
                            <div className={styles.nextPrevLinkHeader}>
                                Prev
                            </div>  
                            <div className={styles.nextPrevLinkLabel}>
                                {prevTreeNode.name}
                            </div>
                        </div>
                    </div>
                </Link>}
            {!prevTreeNode && <div></div>}
            
            {nextTreeNode &&
                <Link to={nextTreeNode.slug}>
                    <div className={cx(styles.nextPrevLink, styles.nextPrevLinkRight)}>
                        <div>
                            <div className={styles.nextPrevLinkHeader}>
                                Next
                            </div>  
                            <div className={styles.nextPrevLinkLabel}>
                                {nextTreeNode.name}
                            </div>
                        </div>
                    </div>
                </Link>}
            
        </div>;
    }

    return (
        <div className={styles.content}>

            {/* <pre>
                {JSON.stringify(data, null, 4)}
            </pre> */}

            {renderPathNavigator()}

            <h1>{title}</h1>

            {remarkHtml && 
                <div className={styles.innerMarkdownContent}
                     dangerouslySetInnerHTML={{ __html: remarkHtml }} />
            }

            {data?.contentData &&
                <div className={styles.innerMarkdownContent}
                     dangerouslySetInnerHTML={{ __html: data.contentData }} />
            }

            {renderValidator()}

            {renderChildrenLinks()}

            {renderNextPrevLinks()}

            {renderValidatorSubmitForm()}

            {renderSocialShare()}
        </div>
    )
}

