// extracted by mini-css-extract-plugin
export var childrenLinkLabel = "styles-module--childrenLinkLabel--PKE6h";
export var childrenLinkWrapper = "styles-module--childrenLinkWrapper--Alahl";
export var childrenLinksContainer = "styles-module--childrenLinksContainer--n07BL";
export var content = "styles-module--content--zNN6M";
export var docsNavIcon = "styles-module--docsNavIcon--ZKShL";
export var externalLink = "styles-module--externalLink--o-3uB";
export var formWrapper = "styles-module--formWrapper--4pvyX";
export var innerMarkdownContent = "styles-module--innerMarkdownContent--iOBuD";
export var navigationLinkLabel = "styles-module--navigationLinkLabel--VhG3j";
export var navigationLinkSeparator = "styles-module--navigationLinkSeparator--1wzJs";
export var navigationLinkWrapper = "styles-module--navigationLinkWrapper--PcYOI";
export var navigationLinksContainer = "styles-module--navigationLinksContainer--hcDdQ";
export var nextPrevLink = "styles-module--nextPrevLink--V+1bA";
export var nextPrevLinkHeader = "styles-module--nextPrevLinkHeader--zK9S-";
export var nextPrevLinkLabel = "styles-module--nextPrevLinkLabel--oiLxP";
export var nextPrevLinkLeft = "styles-module--nextPrevLinkLeft--CAUJM";
export var nextPrevLinkRight = "styles-module--nextPrevLinkRight--T1IkG";
export var nextPrevLinks = "styles-module--nextPrevLinks--2edFE";
export var socialNetworks = "styles-module--socialNetworks--uWX-y";
export var socialNetworksShareMsg = "styles-module--socialNetworksShareMsg--p3hWu";